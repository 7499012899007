import React, { useContext } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Link from '../components/Link'
import Layout from '../components/Layout'
import { SharedContainer } from '../styledComponents'
import {
    getAllImagesByCategory,
    getAllImagesByCategory_allPersonalProjects,
    getAllImagesByCategory_allCommissionedProjects,
} from './__generated__/getAllImagesByCategory'
import { shuffleArray } from '../utils/algo'
import Text from '../components/Text'
import { mq, spacing } from '../utils/presets'
import { SeedContext } from '../../wrap-element'
import SEO from '../components/SEO'

const CollectionContainer = styled(SharedContainer)`
    flex: 1;
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    ${mq.laptop} {
        flex-direction: row;
    }
`
const Gap = styled.div`
    width: 100%;
    height: 6px;
    ${mq.tablet} {
        height: 10px;
    }
    ${mq.laptop} {
        width: 10px;
        height: 100%;
    }
`

const ImageContainer = styled.div`
    flex: 1;
    position: relative;
    overflow: hidden;
`

const Caption = styled(Text)`
    color: white;
`

const CaptionWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledBackgroundImage = styled(Img)`
    height: 100%;
    max-height: 100%;
`

export const projectQuery = graphql`
    fragment ProjectData on MdxConnection {
        edges {
            node {
                id
                fields {
                    path
                }
                frontmatter {
                    images {
                        featured
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default function Landing() {
    const [hasMounted, setHasMounted] = React.useState(false)

    React.useEffect(() => {
        setHasMounted(true)
    }, [])

    if (!hasMounted) {
        return null
    }

    const data: getAllImagesByCategory = useStaticQuery(graphql`
        query getAllImagesByCategory {
            infoPage: mdx(fileAbsolutePath: { glob: "**/pages/info.md" }) {
                frontmatter {
                    landingTitle
                    seoDescription
                }
            }
            allPersonalProjects: allMdx(
                filter: {
                    fields: { category: { eq: "personal" } }
                    frontmatter: {
                        images: { elemMatch: { featured: { eq: true } } }
                    }
                }
            ) {
                ...ProjectData
            }
            allCommissionedProjects: allMdx(
                filter: {
                    fields: { category: { eq: "commissioned" } }
                    frontmatter: {
                        images: { elemMatch: { featured: { eq: true } } }
                    }
                }
            ) {
                ...ProjectData
            }
        }
    `)
    const seed = useContext(SeedContext)
    const personalProjects: getAllImagesByCategory_allPersonalProjects = shuffleArray(
        data.allPersonalProjects.edges,
        seed
    )
    const commissionedProjects: getAllImagesByCategory_allCommissionedProjects = shuffleArray(
        data.allCommissionedProjects.edges,
        seed
    )

    const personalProject = personalProjects[0]
    const commissionedProject = commissionedProjects[0]

    const personalImages = shuffleArray(
        personalProject.node.frontmatter.images.filter((i) => i.featured),
        seed
    )
    const commissionedImages = shuffleArray(
        commissionedProject.node.frontmatter.images.filter((i) => i.featured),
        seed
    )

    const personalImage = personalImages[0]
    const commissionedImage = commissionedImages[0]

    return (
        <Layout fullPage>
            <SEO description={data.infoPage.frontmatter.seoDescription} />
            <CollectionContainer>
                <ImageContainer>
                    {personalImage ? (
                        <Link to={'/personal'}>
                            <StyledBackgroundImage
                                fluid={
                                    personalImage.image.childImageSharp.fluid
                                }
                                loading="eager"
                            />

                            <CaptionWrapper>
                                <Caption type="h1">PERSONAL</Caption>
                            </CaptionWrapper>
                        </Link>
                    ) : null}
                </ImageContainer>
                <Gap />
                <ImageContainer>
                    {commissionedImage ? (
                        <Link to={'/commissioned'}>
                            <StyledBackgroundImage
                                fluid={
                                    commissionedImage.image.childImageSharp
                                        .fluid
                                }
                                loading="eager"
                            />
                            <CaptionWrapper>
                                <Caption type="h1">COMMISSIONED</Caption>
                            </CaptionWrapper>
                        </Link>
                    ) : null}
                </ImageContainer>
            </CollectionContainer>
        </Layout>
    )
}
